
<template>
  <div>
    <v-card style='border-radius: 10px;' flat class="pb-3">
      <v-card-title v-if="is_component_use_in_student_access && !is_student_list_waiting_selection" class='primary white--text py-3 px-5'>
        <v-col cols='12' class='pa-0'>
          <div class='title'>Cursos matriculados</div> 
        </v-col>
      </v-card-title>
      <v-card-title v-if="is_student_list_waiting_selection && is_component_use_in_student_access" class='primary white--text py-3 px-5'>
        <v-col cols='12' class='pa-0'>
          <div class='title'>Aguardando seleção</div> 
        </v-col>
      </v-card-title>
      <v-card-title v-if="!is_component_use_in_student_access && !default_title_calendar" class='primary white--text py-3 px-5'>
        <v-col cols='12' class='pa-0 mb-2'>
          <div class="caption">
            <!-- {{ $dayjs.weekdays()[$dayjs().day()].toUpperCase() }} -->
            {{ $dayjs().format('dddd').toUpperCase() }}
          </div>
        </v-col>
        <v-col cols='12' class='pa-0'>
          <v-row class='px-3' justify='space-between'>
            <div class='title'>{{ $dayjs().format('DD [de] MMMM').toUpperCase() }}</div>
            <div class='headline font-weight-bold pr-8'>{{ $dayjs().format('YYYY')}}</div> 
          </v-row>
        </v-col>
      </v-card-title>
      <v-card-title v-if="!default_title_calendar && !is_component_use_in_student_access" class='primary white--text py-3 px-5'>
        <span>{{card_title}}</span>
      </v-card-title>
      <card-loading-transparent v-if="loading"/>
      <v-card-text :class="[is_component_use_in_student_access ? 'mt-6' : 'mt-0' , 'px-0']">
        <div v-if="!loading && items.length <= 0 && is_component_use_in_student_access" class="grey--text title mt-6 mb-n1" style="text-align: center">
          Você não está matriculado em nenhum
        </div>
        <div>
          <v-data-iterator 
            :items="itemsFiltered" 
            :items-per-page.sync="itemsPerPage" 
            :page.sync="page" 
            :search="search || status_search"
            :sort-by="sortBy.toLowerCase()" 
            :sort-desc="sortDesc" 
            v-if="!loading" 
            hide-default-footer
          >
            <template v-slot:no-data>
              <div v-if="$route.name == 'CourseList'">
                <v-divider class="my-1 mt-5"></v-divider>
                <div class="d-flex justify-center py-3 text-subtitle-2">
                  Nenhum curso registrado para o ano selecionado. 
                </div>
                <v-divider class="my-1"></v-divider>
              </div>
            </template>
            <template v-slot:no-data>
              <div v-if="['MyCourseList', 'Home'].includes($route.name)"></div>
            </template>
            <template v-slot:no-results> 
              <div v-if="$route.name == 'CourseList'">
                <v-divider class="my-1 mt-5"></v-divider>
                <div class="d-flex justify-center py-3 text-subtitle-2">
                  Nenhum resultado encontrado.      
                </div>
                <v-divider class="my-1"></v-divider>
              </div>
            </template>
            <template v-slot:header>
                <!-- <v-col cols='6' :sm="['student'].includes(user.role.name) ? 12 : 6" class="pa-3 pr-0"> -->
              <v-row class="px-3 pb-2 mt-n6 d-flex justify-center" align="center" align-content="space-between" v-if="!is_component_use_in_student_access">
                  
                <v-text-field 
                  v-model="search" 
                  outlined 
                  flat 
                  clearable 
                  solo 
                  hide-details 
                  prepend-inner-icon="mdi-magnify" 
                  label="Buscar" 
                  :class="['my-2 ml-3', $vuetify.breakpoint.xs ? 'mr-3' : 'mr-2']" 
                  dense 
                ></v-text-field>

                <v-select 
                  :menu-props="{ bottom: true, offsetY: true }" 
                  solo 
                  dense 
                  hide-details 
                  clearable 
                  outlined
                  flat
                  :class="[$vuetify.breakpoint.xs ? 'mx-3 my-2' : 'mr-2']"  
                  label="Filtrar cursos por status"
                  item-text="title" 
                  item-value="key"
                  v-model="status_search"
                  v-if="!['student'].includes(user.role.name)"
                  :items="status_map"
                  :prepend-inner-icon="status_search ? status_map.filter(e=> e.key == status_search)[0].icon : 'mdi-filter'"
                >
                  <template v-slot:item='{ item }'>
                    <v-icon class="mr-3">{{ item.icon }}</v-icon>{{item.title}}
                  </template>
                </v-select>
                
                <v-col class="py-2 pb-2">
                  <slot name="year_picker"></slot>
                </v-col>

                <div class="my-2 ml-2" v-if="$vuetify.breakpoint.mdAndUp">
                  <template>
                      <v-btn-toggle v-model="sortDesc" dense class="mr-3">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue" :value="false" v-bind="attrs" v-on="on">
                              <v-icon color="white" >mdi-arrow-up</v-icon>
                            </v-btn>
                          </template>
                          <v-icon dark>mdi-sort-reverse-variant</v-icon><span class="ml-3">Data de Início</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue" :value="true"  v-bind="attrs" v-on="on">
                              <v-icon color="white">mdi-arrow-down</v-icon>
                            </v-btn>
                          </template>
                          <v-icon dark>mdi-sort-variant</v-icon><span class="ml-3">Data de Início</span>
                        </v-tooltip>
                      </v-btn-toggle>
                  </template>
                </div>
              </v-row>
              <slot name="status_counter"></slot>
            </template>

            <template v-slot:default="props">
              <v-row :class="$vuetify.breakpoint.smAndUp ? 'px-6 pr-3' : 'pl-5 px-2'" v-if="props.items.length > 0">
                <v-col v-for="item in props.items" :key="item.idx" cols="12" :sm="is_component_use_in_student_access ? 12 : 6" class="pa-0 pr-3 pb-3">

                  
                  <v-card min-height="100%" class="d-flex flex-column" outlined style="border-left: 5px solid #1976D2;">
                    <v-card-text class="text--darken-4 py-2 px-6">
                      <course-list-data-iterator-content
                        :is_component_use_in_student_access="is_component_use_in_student_access"
                        :is_student_list_waiting_selection="is_student_list_waiting_selection"
                        :course="item"
                      />
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions class="d-flex justify-end pb-2">
                      <v-btn class="primary" small @click="courseDetailsDialog(item)">
                        <v-icon>mdi-plus</v-icon> DETALHES
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  
                </v-col>
              </v-row>
            </template>

            <template v-slot:footer>
              <v-row class="mx-5 mb-0 mt-4" align="center" justify="center" v-if="1 < numberOfPages">
                <span class="grey--text">Itens por página</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on" >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage(number)">
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <span class="mr-4 grey--text">
                  Pagina {{ page }} de {{ numberOfPages }}
                </span>
                <div>
                  <v-btn fab dark color="blue darken-3" x-small class="mr-1 elevation-0" @click="formerPage">
                    <v-icon size='22'>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab dark color="blue darken-3" x-small class="ml-1 elevation-0" @click="nextPage">
                    <v-icon size='22'>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </template>
          </v-data-iterator>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" :fullscreen="!$vuetify.breakpoint.smAndUp" max-width="900">
      <v-card>
        <v-card-title class='primary white--text py-3 px-5'>
          <span>Detalhes do curso</span>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-3 px-3" :style="responsivity_vh">
          <course-details :selected_course="selected_course"></course-details>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="user.role.name == 'student' ? 'pb-10' : 'pb-3'">
          <selected-course-card-actions v-if="dialog" :selected_course="selected_course"></selected-course-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'CourseList',
  props: {
    is_component_use_in_student_home: {
      type: Boolean,
      default: false
    },
    is_component_use_in_student_access: {
      type: Boolean,
      default: false
    },
    is_student_list_waiting_selection: {
      type: Boolean,
      default: false
    },
    card_title: {
      type: String
    },
    default_title_calendar:{
      type: Boolean,
      default: false
    },
    current_year: {
      type: Number,
      default: null
    },
  },
  components: {
    CourseListDataIteratorContent: () => import ('./CourseListDataIteratorContent.vue'),
    SelectedCourseCardActions: () => import('../../courses/shared/SelectedCourseCardActions.vue'),
    CourseDetails: () => import('../../courses/CourseDetails.vue')
  },
  data() {
    return {
      loading: false, 
      itemsPerPageArray: [4, 8, 16],
      search: '',
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: 'start_date',
      items: [],
      dialog: false,
      selected_course: {},
      status_map: [
        { action: (e) => this.statusFilter(e), key: 'active', icon: 'mdi-bookmark-check', title: 'Ativo' }, 
        { action: (e) => this.statusFilter(e), key: 'selecting', icon: 'mdi-clipboard-clock-outline', title: 'Seleção' }, 
        { action: (e) => this.statusFilter(e), key: 'done_selection', icon: 'mdi-check-circle-outline', title: 'Seleção pronta' }, 
        { action: (e) => this.statusFilter(e), key: 'in_progress', icon: 'mdi-progress-clock', title: 'Em andamento' }, 
        { action: (e) => this.statusFilter(e), key: 'done', icon: 'mdi-check-all', title: 'Finalizado' }, 
        { action: (e) => this.statusFilter(e), key: 'suspended', icon: 'mdi-alert-circle-outline', title: 'Suspenso' }
      ],
      status_search: '',
    }
  },
  methods: {
    statusFilter(e){
      this.search = ''
      this.status_search = e
    },
    setLoading(){
      let filter = {}
      if(this.is_component_use_in_student_access){
        filter = {
          is_component_use_in_student_access: this.is_component_use_in_student_access,
          is_student_list_waiting_selection: this.is_student_list_waiting_selection,
        }
      }else{
        filter = {
          start_date: this.$dayjs(this.current_year.toString()).startOf('year').format('YYYY-MM-DD'),
          end_date: this.$dayjs(this.current_year.toString()).endOf('year').format('YYYY-MM-DD'),
        }
      }
         
      this.loading = true
      this.getCourses(filter).then(()=>{
        this.items = this.all_courses
        
        if(this.all_courses.length > 0 && !this.is_component_use_in_student_access) {
          this.$emit('empityCourses', false)
        }else{
          this.$emit('empityCourses', true)
        }
        setTimeout(() => {
          this.loading = false
        }, 500);
      })

     
    },
    courseDetailsDialog(item){
      if(this.is_component_use_in_student_home){
        this.$emit('courseDetails', item)
      }else{
        this.selected_course = item
        this.dialog = true
      }
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    ...mapActions({
      getCourses: 'Course/index',
      getCoursesByUserId: 'Course/indexByUserId',
      addNotification: 'Notification/add'
    })
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.itemsFiltered.length / this.itemsPerPage) || 1
    },
    itemsFiltered(){
      if(this.search && this.status_search){
        let exp = new RegExp(this.search.trim(), "i");
        let items_filtered_by_status = this.items.filter(obj=> obj.status === this.status_search)
        return items_filtered_by_status.filter((obj) => exp.test(obj.name));

      }else if(this.status_search){
        return this.items.filter(obj=> obj.status === this.status_search)
        
      }else{
        return this.items
      }
    },
    responsivity_vh(){
      return this.$vuetify.breakpoint.lg ? 
      'max-height: 60vh ; overflow-y: auto' : 
      this.$vuetify.breakpoint.smAndDown ? 
      'max-height: 60vh ; overflow-y: auto' : 
      'max-height: 70vh ; overflow-y: auto'
    },
    ...mapState({
      all_courses: state=> state.Course.all_courses,
      user: state=> state.User.user
    })
  },
  mounted () {
    this.setLoading();
  },
  watch: {
    current_year() {
      this.setLoading();
    }
  },
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgb(206, 206, 206);
}
.v-text-field--outlined:hover >>> fieldset {
  border-color: #1976D2;
}

</style>